import React from "react"
import { graphql } from "gatsby"
import "./index.scss"

export default function Home({ data }) {
  const categorySkillsMap = new Map();
  for (const { childMarkdownRemark: node } of data.skills.nodes) {
    let skills = categorySkillsMap.get(node.frontmatter.category)
    if (skills === undefined) {
      skills = []
      categorySkillsMap.set(node.frontmatter.category, skills)
    }
    skills.push(node)
  }
  const categories = data.categories.nodes
    .map(x => x.childMarkdownRemark)
    .sort(({ frontmatter: a }, { frontmatter: b }) => {
      if (typeof a.priority === 'number') {
        return typeof b.priority === 'number' ? a.priority - b.priority : -1
      } else {
        return typeof b.priority === 'number' ? 1 : a.name.localeCompare(b.name)
      }
    })
  return (
    <>
      {categories.map(({ id, html, frontmatter }, index) => {
        const skills = categorySkillsMap.get(frontmatter.name)
        return (
          <section key={id} className={frontmatter.name}>
            {index === 0 ? <h1>{frontmatter.title}</h1> : <h2>{frontmatter.title}</h2>}
            <section className="category-description" dangerouslySetInnerHTML={{ __html: html }} />
            {skills ? (
              <section className="skill-container">
                {skills.map(skill => (
                  <article key={skill.id} className="skill">
                    {skill.frontmatter.title}
                  </article>
                ))}
              </section>
            ) : null}
          </section>
        )
      })}
    </>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    categories: allFile(filter: {sourceInstanceName: {eq: "categories"}}) {
      nodes {
        childMarkdownRemark {
          id
          html
          frontmatter {
            priority
            name
            title
          }
        }
      }
    }
    skills: allFile(filter: {sourceInstanceName: {eq: "skills"}}) {
      nodes {
        childMarkdownRemark {
          id
          html
          frontmatter {
            category
            title
            type
          }
        }
      }
    }
  }
`
